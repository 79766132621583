.tabular-history .table-container {
  overflow-y: scroll;
  flex-grow: 1;
}

.tabular-history > div {
  display: flex;
  flex-direction: column;
}

.tabular-history table {
  width: 100%;
}

.tabular-history table tr {
  height: 2rem;
}

.tabular-history table tr:nth-child(2n) {
  background-color: #4d5274;
}

.tabular-history table td {
  border-spacing: 0;
  border: none;
}

.dashboard {
  display: grid;
  width: 100%;
  height: 100vh;
  background-color: #25294a;

  grid-template-columns: [sidebar-start] 20ch [sidebar-end c1-start] 1fr [c1-end c2-start] 1fr [c2-end];
  grid-template-rows: [header-start] 1fr [header-end r1-start] 1fr [r1-end r2-start] 1fr [r2-end r2-start] 1fr [r3-end footer-start] 3rem [footer-end];

  grid-template-areas:
    "sidebar block1 block3"
    "sidebar block2 block3"
    "sidebar block2 block4"
    "sidebar block2 block4"
    "sidebar footer footer";
}

@media screen and (max-width: 768px){
  .dashboard{
    height:auto;
    grid-template-rows: 5rem 10rem 20rem 20rem 20rem 2rem;
    grid-template-columns: auto;

    grid-template-areas:
      "sidebar"
      "block1"
      "block2"
      "block3"
      "block4"
      "footer"
  }

 
}

.dashboard section {
  padding: 1rem;
}

.dashboard section > * {
  height: 100%;
  padding: 0.5rem;
  background-color: #2d325a;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
}

section.tabular-history {
  grid-area: block2;
}

section.daily-cases {
  grid-area: block1;
}

section.case-history {
    grid-area: block3;
  }

section.daily-rate{
    grid-area:block4;
}

.widget h2 {
  font-size: 1rem;
  font-weight: 100;
  margin-top: 0;
}
.sidebar {
  background: #202442;
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 0;
}

.sidebar__footer img {
  width: 32px;
  margin: 0.5rem;
}

.sidebar__header img {
  width: 40%;
}

.sidebar__header {
    line-height: 3rem;
}

.sidebar__header p {
    padding:0 1rem;
    line-height: 1.5rem;
}

@media screen and (max-width: 768px){
  .sidebar{
    flex-direction: row;
    padding:0;
  }

  .sidebar h1{
    font-size: 1rem;
    flex-grow:1;
  }

  .sidebar__header{
    line-height: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
    padding: 0 1rem;
  }

  .sidebar__header img{
    width:3rem;
    
  }

  .sidebar__header p {
    display: none;
  }

  .sidebar__footer{
    display: flex;
    place-items: center;
  }
}